import React from 'react';

export const Error = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_11692_15667)">
        <circle cx="7" cy="7" r="6.5" stroke="#CC0000" />
        <path
          d="M8 1.935L7.776 8.697H6.124L5.886 1.935H8ZM6.992 11.973C6.63733 11.973 6.34333 11.8657 6.11 11.651C5.886 11.427 5.774 11.1517 5.774 10.825C5.774 10.4983 5.886 10.2277 6.11 10.013C6.34333 9.789 6.63733 9.677 6.992 9.677C7.33733 9.677 7.622 9.789 7.846 10.013C8.07 10.2277 8.182 10.4983 8.182 10.825C8.182 11.1517 8.07 11.427 7.846 11.651C7.622 11.8657 7.33733 11.973 6.992 11.973Z"
          fill="#CC0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_11692_15667">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
