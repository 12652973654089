import React from 'react';

export const Check = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.85771 21.577C10.4832 21.577 10.9731 21.3313 11.3171 20.8296L21.074 6.04459C21.3242 5.67599 21.4284 5.3381 21.4284 5.0207C21.4284 4.1811 20.803 3.57701 19.9274 3.57701C19.3228 3.57701 18.9579 3.78179 18.5931 4.35517L9.81602 17.9934L5.33367 12.4439C4.97925 12.0241 4.61441 11.8296 4.0932 11.8296C3.20716 11.8296 2.57129 12.4439 2.57129 13.2937C2.57129 13.6623 2.69638 14.0105 3.01952 14.3791L8.40877 20.8808C8.81531 21.362 9.26354 21.577 9.85771 21.577Z"
        fill="url(#paint0_linear_10852_888)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10852_888"
          x1="5.68226"
          y1="19.9081"
          x2="17.6029"
          y2="4.76831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21A038" />
          <stop offset="1" stopColor="#0CCEC2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
