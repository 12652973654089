import { configureStore } from '@reduxjs/toolkit';
import { bookingReducer } from './booking';
import { usersReducer } from './users';
import { placeReducer } from './place';
import { settingsReducer } from './settings';
import { dateReducer } from './date';

export default configureStore({
  reducer: {
    booking: bookingReducer,
    users: usersReducer,
    place: placeReducer,
    settings: settingsReducer,
    date: dateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
