import React from 'react';
import './NotificationPage.scss';
import { Check } from '../icons';
import { Trans } from 'react-i18next';

const NotificationPage = ({ errors }) => {
  return (
    <div className={`notification ${errors ? 'notification--error' : 'notification--success'}`}>
      <div className="notification_wrapper gradient-border">
        <div className="notification_modal">
          <div className="notification_title">
            {errors ? <Trans i18nKey={`notification.title.error`} /> : <Trans i18nKey={`notification.title.success`} />}
          </div>
          <div className="notification_icon">{!errors && <Check />}</div>
          <div className="notification_text">
            {errors ? errors.message : <Trans i18nKey="notification.text.success" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
