import React from 'react';

export const ArrowRight = (props) => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.632053 0.918801C1.06463 0.558317 1.76599 0.558317 2.19857 0.918801L8.29087 5.99572C8.72346 6.35621 8.72346 6.94067 8.29087 7.30115L2.19857 12.3781C1.76599 12.7386 1.06463 12.7386 0.632053 12.3781C0.199472 12.0176 0.199472 11.4331 0.632053 11.0726L5.9411 6.64844L0.632053 2.22423C0.199472 1.86374 0.199472 1.27928 0.632053 0.918801Z"
        fill="url(#paint0_linear_10631_7672)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10631_7672"
          x1="1.67818"
          y1="11.5358"
          x2="9.72955"
          y2="4.77832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21A038" />
          <stop offset="1" stopColor="#0CCEC2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
