import { addDays, endOfMonth, endOfWeek, isBefore, startOfMonth, startOfWeek } from 'date-fns';

export const chunks = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size));

export const getDaysInMonth = (date, locale, weekStartsOn) => {
  const startWeek = startOfWeek(startOfMonth(date), { locale, weekStartsOn });
  const endWeek = endOfWeek(endOfMonth(date), { locale, weekStartsOn });
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};
