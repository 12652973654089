import React from 'react';

export const ArrowLeft = (props) => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98318 12.3781C7.5506 12.7386 6.84925 12.7386 6.41667 12.3781L0.324359 7.30115C-0.108221 6.94067 -0.108221 6.35621 0.324359 5.99572L6.41667 0.918801C6.84925 0.558317 7.5506 0.558317 7.98318 0.918801C8.41576 1.27929 8.41576 1.86374 7.98318 2.22423L2.67413 6.64844L7.98318 11.0726C8.41576 11.4331 8.41576 12.0176 7.98318 12.3781Z"
        fill={props.disabled ? '#A1AEB7' : 'url(#paint0_linear_10631_7672)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_10631_7672"
          x1="1.67818"
          y1="11.5358"
          x2="9.72955"
          y2="4.77832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21A038" />
          <stop offset="1" stopColor="#0CCEC2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
