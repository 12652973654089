import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './SliderRange.scss';
import MultiRangeSlider from './MultiRangeSlider';
import { converHoursInMinutes, convertMinutesInHours } from '../services/utils/date';
import format from 'date-fns/format';
import set from 'date-fns/set';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';

const SliderRange = () => {
  const { getStartWorkDay, getEndWorkDay } = useDefaultBookingTime();
  const sliderRef = useRef();
  const sliderTimeRef = React.createRef();
  const [valuesScale, setValuesScale] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [sliderTrackWorkingHours, setSliderTrackWorkingHours] = useState([]);
  const [valueSliderCurrentTime, setValueSliderCurrentTime] = useState('');
  const redux = useSelector((state) => ({
    bookDate: state.date.bookDay,
    availableList: state.booking.availableList,
  }));
  const minValueSlider = useMemo(() => converHoursInMinutes(getStartWorkDay(redux.bookDate)), [redux.bookDate]);
  const maxValueSlider = useMemo(() => converHoursInMinutes(getEndWorkDay(redux.bookDate)), [redux.bookDate]);
  const getPercent = useCallback(
    (value) =>
      Math.round(
        ((value - converHoursInMinutes(getStartWorkDay(redux.bookDate))) /
          (maxValueSlider - converHoursInMinutes(getStartWorkDay(redux.bookDate)))) *
          100,
      ),
    [maxValueSlider],
  );
  const getPositionValueCurrentTime = (ref) => {
    const percentValue = getPercent(converHoursInMinutes(new Date()));
    for (let i = 0; i < ref.children.length; i++) {
      if (ref.children[i].className === 'input-time-value') {
        ref.children[i].style.left = `${percentValue - 5}%`;
      }
    }
  };

  const getPositionRange = (min) => {
    if (!valuesScale.length) return null;
    const startPositionElem = valuesScale[0].offsetLeft;
    const widthBtwScaleValues = valuesScale[1].offsetLeft - valuesScale[0].offsetLeft;
    const minValueScale = find(valuesScale, (el) => el.attributes[0].value >= min);
    if (minValueScale) {
      const percentFilledScale = ((+minValueScale?.attributes[0].value - min) / 60) * 100;
      const width = (widthBtwScaleValues * percentFilledScale) / 100;
      const offsetLeftStart = minValueScale?.offsetLeft - startPositionElem - width;
      return `${offsetLeftStart}px`;
    }
  };

  const getWidthRange = (min, max) => {
    if (!valuesScale.length) return null;
    const minValueScale = find(valuesScale, (el) => el.attributes[0].value <= min);
    const maxValueScale = find(valuesScale, (el) => el.attributes[0].value >= max);
    if (minValueScale && maxValueScale) {
      const fullWidthScale = maxValueScale?.offsetLeft - minValueScale?.offsetLeft;
      const percentFilledScale =
        ((max - min) / (maxValueScale?.attributes[0].value - minValueScale?.attributes[0].value)) * 100;
      const widthRange = (fullWidthScale * percentFilledScale) / 100 + maxValueScale?.offsetWidth;
      return `${widthRange}px`;
    }
  };

  const getTrackWithWorkHour = () => {
    const result = [];
    const startDay = getStartWorkDay(redux.bookDate);
    const endDay = getEndWorkDay(redux.bookDate);
    let workHours = endDay.getHours() - startDay.getHours();
    const startSlide = converHoursInMinutes(startDay);
    for (let i = 0; i <= workHours; i++) {
      result.push({
        minutes: startSlide + 60 * i,
        hour: set(new Date(), { hours: startDay.getHours() + i, minutes: 0, seconds: 0 }),
      });
    }
    return result;
  };

  const transformBookings = (availableBookings) => {
    if (!availableBookings.length) return [];
    return availableBookings.map((el, i) => {
      const min = converHoursInMinutes(el.startTime);
      const max = converHoursInMinutes(el.endTime);
      return {
        min,
        max,
      };
    });
  };

  const changeBookingList = () => {
    setBookings(transformBookings(redux.availableList));
  };

  useEffect(() => {
    setSliderTrackWorkingHours(getTrackWithWorkHour());
    setValueSliderCurrentTime(converHoursInMinutes(new Date()));
    if (sliderRef.current) {
      setValuesScale(sliderRef.current.nextElementSibling.childNodes);
    }
  }, [bookings]);

  useEffect(() => {
    if (sliderTimeRef.current) {
      getPositionValueCurrentTime(sliderRef.current);
    }
  }, [sliderTimeRef]);

  useEffect(() => {
    changeBookingList();
  }, [redux.availableList]);

  useEffect(() => {
    window.addEventListener('resize', changeBookingList);
    return () => {
      window.removeEventListener('resize', changeBookingList);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSliderTrackWorkingHours(getTrackWithWorkHour());
      setValueSliderCurrentTime(converHoursInMinutes(new Date()));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-container">
      <div className="slider_track" ref={sliderRef}>
        {bookings.map((el, i) => (
          <MultiRangeSlider
            key={`${el.max}${i}`}
            min={el.min}
            positionRange={getPositionRange(el.min)}
            max={el.max}
            widthRange={getWidthRange(el.min, el.max)}
          />
        ))}
        {new Date() < getEndWorkDay(redux.bookDate) && new Date() > getStartWorkDay(redux.bookDate) && (
          <>
            <input
              type="range"
              className="input-time"
              min={minValueSlider}
              max={maxValueSlider}
              value={valueSliderCurrentTime}
              readOnly
            />
            <div ref={sliderTimeRef} className="input-time-value">
              {convertMinutesInHours(valueSliderCurrentTime)}
            </div>
          </>
        )}
      </div>
      <div className="slider_values">
        {sliderTrackWorkingHours.map((item, i) => (
          <div key={item.minutes} value={item.minutes} className="slider_time">
            {format(new Date(item.hour), 'h')}
          </div>
        ))}
      </div>
      <div className="slider_time-am">
        {sliderTrackWorkingHours.length ? format(new Date(sliderTrackWorkingHours[0].hour), 'a') : ''}
      </div>
      <div className="slider_time-pm">
        {sliderTrackWorkingHours.length
          ? format(new Date(sliderTrackWorkingHours[sliderTrackWorkingHours.length - 1].hour), 'a')
          : ''}
      </div>
    </div>
  );
};

export default SliderRange;
