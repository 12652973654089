import React, { useContext, useEffect, useState } from 'react';
import { PATH_APP } from '../routes';
import { Trans, useTranslation } from 'react-i18next';
import { User, Check } from '../icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './UsersPage.scss';
import Spinner from '../components/Spinner';
import { getUsers, setUserSelected } from '../redux/users';
import { sendActionLogs } from '../services/utils/logs';
import { DeviceAuthContext } from '@inspace-org/react-auth';

const UsersPage = () => {
  const { t } = useTranslation();
  const { device, companySettings, settings } = useContext(DeviceAuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const redux = useSelector((state) => ({
    users: state.users.list,
    selectedUser: state.users.userSelected,
    loadedUsers: state.users.loadedUsers,
    placeInfo: state.place.info,
    errors: state.booking.errors,
  }));

  useEffect(() => {
    try {
      dispatch(getUsers());
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    setFilteredUsers(redux.users);
    setSelectedUser(redux.selectedUser);
    if (redux.selectedUser) {
      setValue(`${redux.selectedUser.firstName} ${redux.selectedUser.lastName}`);
    }
  }, [redux.users]);

  const handleSelectUser = (user) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Click to select user in users list',
      actionPath: window.location.pathname,
    });
    setError(false);
    setSelectedUser(user);
    dispatch(setUserSelected(user));
    setValue(`${user.firstName} ${user.lastName}`);
  };

  const handleNext = () => {
    if (!selectedUser) {
      setError(true);
      return;
    }
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Click to Next button',
      actionPath: window.location.pathname,
    });
    navigate(PATH_APP.booking);
  };

  const filterUsers = (e) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Change input user',
      actionPath: window.location.pathname,
    });
    setError(false);
    const value = e.target.value.toLowerCase().trim();
    const result = redux.users
      .filter(
        (user) =>
          user.firstName.toLowerCase().indexOf(value) !== -1 || user.lastName.toLowerCase().indexOf(value) !== -1,
      )
      .map((item) => item);
    if (value === '') {
      setSelectedUser(null);
      setError(true);
      dispatch(setUserSelected(null));
    }
    setValue(value);
    setFilteredUsers(result);
  };

  const handlePreviousPage = () => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Click on back button`,
      actionPath: window.location.pathname,
    });
    navigate(PATH_APP.room);
  };

  return (
    <div className="content_item-booking">
      <button className="button-back" onClick={handlePreviousPage}>
        <div className="button-back_arrow" />
        <div>
          <Trans i18nKey="users.title" />
        </div>
      </button>
      <div className="users">
        <div className="users_item input_wrapper">
          <input
            className="input_elem"
            type="text"
            name="user"
            value={value}
            onChange={filterUsers}
            placeholder={t('users.placeholder')}
          />
          <div className="input_icon">
            <User />
          </div>
          {error && (
            <div className="users_error">
              <Trans i18nKey="users.empty-error" />
            </div>
          )}
        </div>
        <div className="user users_item">
          {redux.loadedUsers ? (
            <Spinner />
          ) : (
            <>
              <div className="users_name">
                <Trans i18nKey={'users.list-title'} />
              </div>
              {filteredUsers.map((user) => (
                <div key={user._id} className="user_item" onClick={(e) => handleSelectUser(user)}>
                  <div className="user_avatar">
                    <div className="user_avatar--wrapper">
                      {user.avatar ? <img src={user.avatar} alt="" /> : <User fill="#fff" />}
                    </div>
                  </div>
                  <div className="user_info">
                    <div className="user_name">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="user_position">{user.position}</div>
                  </div>
                  <div className="user_check">{selectedUser?._id === user._id && <Check />}</div>
                </div>
              ))}
            </>
          )}
        </div>
        <button className="button users_button" onClick={handleNext}>
          <Trans i18nKey="button_next" />
        </button>
      </div>
    </div>
  );
};

export default UsersPage;
