import {
  DEFAULT_LS_ACCESS_TOKEN_KEY,
  DEFAULT_LS_EXCHANGE_TOKEN_KEY,
  DEFAULT_LS_REFRESH_TOKEN_KEY,
  LocalApiCredentials,
} from '@inspace-org/client';

export const apiCredentials = new LocalApiCredentials({
  exchangeTokenKey: `ROOM_PHONE${DEFAULT_LS_EXCHANGE_TOKEN_KEY}`,
  accessTokenKey: `ROOM_PHONE${DEFAULT_LS_ACCESS_TOKEN_KEY}`,
  refreshTokenKey: `ROOM_PHONE${DEFAULT_LS_REFRESH_TOKEN_KEY}`,
});
