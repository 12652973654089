import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/http';
import * as Sentry from '@sentry/react';

const initialState = {
  minBookingInterval: null,
  workDayInHours: null,
};

const slice = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    startLoading() {},
    hasError(state, action) {
      Sentry.captureException(action.payload);
      console.error(action.payload);
    },
    getSettingsSuccess(state, action) {
      state.minBookingInterval = action.payload.minBookingInterval;
      state.workDayInHours = action.payload.workDayInHours;
    },
  },
});

// Reducer
export const settingsReducer = slice.reducer;

// ----------------------------------------------------------------------

export const getSettings = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await api.get('/v1/company-settings').then((res) => {
      dispatch(slice.actions.getSettingsSuccess(res));
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};
// ----------------------------------------------------------------------
