import React, { useState } from 'react';
import { addDays, addMonths, format, getDate, isSameMonth, isToday, startOfWeek, isBefore, isAfter } from 'date-fns';
import { DAYS_IN_WEEK, NavigationMonthAction, NavigationMonthButton } from '../../const/date-picker';
import { chunks, getDaysInMonth } from './utils';
import { ArrowLeft, ArrowRight } from '../../icons';
import Day from './Day';
import './index.scss';
import set from 'date-fns/set';
import useDefaultBookingTime from '../../hooks/useDefaultBookingTime';

const DatePicker = (props) => {
  const today = new Date();
  const { maxBookingDate } = useDefaultBookingTime();
  const [date, setDate] = useState(props.initialDate);
  const [currentMonth, setCurrentMonth] = useState(date || today);

  const onMonthNavigate = (type) => {
    if (type === NavigationMonthButton.PREVIOUS) {
      setCurrentMonth(addMonths(currentMonth, NavigationMonthAction.Previous));
    } else {
      setCurrentMonth(addMonths(currentMonth, NavigationMonthAction.Next));
    }
  };

  const onDayClick = (day) => {
    setDate(day);
    props.onChange(day);
  };

  const weeks = chunks(getDaysInMonth(currentMonth), DAYS_IN_WEEK);

  return (
    <div className="date-picker_wrapper">
      <div className="date-picker_header">
        <div className="date-picker_iconContainer">
          <div className="date-picker_arrow" onClick={() => onMonthNavigate(NavigationMonthButton.PREVIOUS)}>
            <ArrowLeft />
          </div>
        </div>

        <div className="date-picker_text">{format(currentMonth, 'MMMM yyyy')}</div>
        <div className="date-picker_iconContainer">
          <div className="date-picker_arrow" onClick={() => onMonthNavigate(NavigationMonthButton.NEXT)}>
            <ArrowRight />
          </div>
        </div>
      </div>
      <WeekDayNames date={currentMonth} />
      <div className="date-picker_row date-picker_daysContainer">
        {weeks.map((week, idx) => (
          <div key={idx} className="date-picker_row">
            {week.map((day) => {
              const isSelectedDay = format(new Date(date), 'd M') === format(new Date(day), 'd M');
              const endDay = set(day, { hours: 23, minutes: 59, seconds: 59 });
              return (
                <Day
                  filled={isSelectedDay}
                  key={format(day, 'MM-dd-yyyy')}
                  disabled={
                    isBefore(endDay, new Date()) || !isSameMonth(currentMonth, day) || isAfter(endDay, maxBookingDate)
                  }
                  outlined={isToday(day)}
                  hidden={!isSameMonth(currentMonth, day)}
                  onClick={() => onDayClick(day)}
                  value={getDate(day)}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const WeekDayNames = (props) => {
  const { date } = props;

  const firstDateOfWeek = startOfWeek(date);
  const shortWeekDaysArray = Array.from(Array(7)).map((_e, i) => {
    return format(addDays(firstDateOfWeek, i), 'E');
  });

  return (
    <div className="date-picker_weekDaysContainer">
      {shortWeekDaysArray.map((day) => (
        <div key={day} className="date-picker_weekDays">
          {day}
        </div>
      ))}
    </div>
  );
};

export default DatePicker;
