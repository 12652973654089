import React, { useEffect, useState } from 'react';
import './FullscreenButton.scss';
import { Fullscreen, Error } from '../icons';
import { Trans } from 'react-i18next';
import { useFullscreen } from '@inspace-org/react-fullscreen';
import { useSelector } from 'react-redux';
import { sendActionLogs } from '../services/utils/logs';

const FullscreenButton = ({ device, companySettings, settings, placeInfo }) => {
  const { toggleFullscreen } = useFullscreen();
  const redux = useSelector((state) => ({
    errors: state.booking.errors,
  }));
  const [textError, setTextError] = useState(null);
  const [isShowTextError, setShowTextError] = useState(false);

  useEffect(() => {
    setTextError(redux.errors);
  }, [redux.errors]);

  const onClickBtn = () => {
    sendActionLogs({
      placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Toggle Fullscreen Button',
      actionPath: window.location.pathname,
    });
    toggleFullscreen();
  };

  return (
    <div className="fullscreen">
      <button type="button" className="fullscreen__button" onClick={onClickBtn}>
        <p className="fullscreen__text">
          <Trans i18nKey="fullscreen_btn" />
        </p>
        <Fullscreen className="fullscreen__img" />
      </button>
      <div className="fullscreen__version">
        <span>
          <Trans i18nKey="fullscreen_version" />
        </span>
        {textError && (
          <div className="fullscreen_error_icon" onClick={() => setShowTextError(!isShowTextError)}>
            <Error />
          </div>
        )}
        {isShowTextError && <span className="fullscreen_error_text">{textError?.message}</span>}
      </div>
    </div>
  );
};

export default FullscreenButton;
