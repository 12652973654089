import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { isAfter, isBefore, isEqual } from 'date-fns';

const TimeSelection = ({ options, onClick, open, scrollAfter }) => {
  const [optionsWithRefs, setOptionsWithRefs] = useState([]);

  useEffect(() => {
    options.length && open && setOptionsWithRefs(options.map((item) => ({ value: item })));
  }, [options, open]);

  useEffect(() => {
    if (open && optionsWithRefs.length) {
      optionsWithRefs.forEach(({ node, value }, index) => {
        const prevOption = optionsWithRefs[index - 1];
        if (isBefore(prevOption?.value, scrollAfter) && (isEqual(value, scrollAfter) || isAfter(value, scrollAfter))) {
          node.scrollIntoView();
        }
      });
    }
  }, [open, optionsWithRefs]);

  return (
    <ul className="input_select_time_list">
      {!!optionsWithRefs.length &&
        optionsWithRefs.map((item) => (
          <li
            ref={(ref) => {
              if (ref) item.node = ref;
            }}
            key={`startTime${item.value}`}
            value={item.value}
            className="input_select_time_item"
            onClick={onClick}
          >
            {format(item.value, 'h:mm a')}
          </li>
        ))}
    </ul>
  );
};

export default TimeSelection;
