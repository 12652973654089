export const NavigationMonthButton = {
  PREVIOUS: 'previous',
  NEXT: 'next',
};

export const NavigationMonthAction = {
  Previous: -1,
  Next: 1,
};

export const MARKERS = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

export const DAYS_IN_WEEK = 7;
