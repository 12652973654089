import React from 'react';
import * as Sentry from '@sentry/react';
import { sendActionLogs } from '../services/utils/logs';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.setExtra('Error Data', {
      status: error?.status,
      url: error?.url,
      body: error,
    });
    Sentry.captureException(error);
    sendActionLogs({
      actionName: `Error Boundary`,
      actionPath: window.location.pathname,
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
