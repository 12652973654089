import React from 'react';

export const Fullscreen = (props) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line x1="0.511118" y1="22.5006" x2="0.502701" y2="15.5006" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="0.509165" y1="7.5006" x2="0.500748" y2="0.500607" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="21.5092" y1="7.5006" x2="21.5007" y2="0.500607" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="21.5092" y1="22.5006" x2="21.5007" y2="15.5006" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="0.5" y1="22.5" x2="7.5" y2="22.5" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="0.5" y1="0.5" x2="7.5" y2="0.5" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="14.5" y1="0.5" x2="21.5" y2="0.5" stroke="#A1AEB7" strokeLinecap="round" />
      <line x1="14.5" y1="22.5" x2="21.5" y2="22.5" stroke="#A1AEB7" strokeLinecap="round" />
    </svg>
  );
};
