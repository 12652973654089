import React from 'react';

export const Clock = (props) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.9933 29.8627C23.51 29.8627 29.7142 23.6585 29.7142 16.1551C29.7142 8.6384 23.4967 2.43416 15.98 2.43416C8.47663 2.43416 2.28564 8.6384 2.28564 16.1551C2.28564 23.6585 8.48988 29.8627 15.9933 29.8627ZM9.31181 17.8122C8.71525 17.8122 8.25126 17.3482 8.25126 16.7516C8.25126 16.1551 8.71525 15.6911 9.31181 15.6911H14.9327V8.08161C14.9327 7.48505 15.3967 7.02105 15.98 7.02105C16.5899 7.02105 17.0539 7.48505 17.0539 8.08161V16.7516C17.0539 17.3482 16.5899 17.8122 15.98 17.8122H9.31181Z"
        fill="#A1AEB7"
      />
    </svg>
  );
};
