import * as React from 'react';
import './Day.scss';
import classnames from 'classnames';

const Day = (props) => {
  const { disabled, outlined, filled, onClick, value, hidden } = props;
  return (
    <div className={classnames('picker-day_buttonContainer')}>
      <button
        className={classnames(
          'picker-day_button',
          (!disabled && filled && 'filled') || (!disabled && outlined && 'outlined'),
        )}
        disabled={disabled}
        onClick={onClick}
      >
        <span
          className={classnames(
            'picker-day_buttonText',
            disabled && 'disabled',
            (!disabled && filled && 'contrast') || (!disabled && outlined && 'outlinedText'),
          )}
          hidden={hidden}
        >
          {value}
        </span>
      </button>
    </div>
  );
};

export default Day;
