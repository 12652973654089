import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDays, addMinutes, getISODay } from 'date-fns';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { setDefaultTime } from '../redux/date';
import set from 'date-fns/set';
import isToday from 'date-fns/isToday';

const WorkDaySettings = {
  MIDNIGHT: 0,
  NEXT_DAY_MIDNIGHT: 24,
  ELEVEN_PM: 23,
};

const useDefaultBookingTime = () => {
  const dispatch = useDispatch();
  const { companySettings } = useContext(DeviceAuthContext);
  const {
    workDayScheduleDefault,
    workDayScheduleCustomEnabled,
    workDayScheduleCustom,
    maxBookingStartOffset,
    maxBookingStartLimitedBy,
    maxBookingStartDate,
  } = companySettings;
  const [workDaySchedule, setWorkDaySchedule] = useState(
    workDayScheduleCustomEnabled ? workDayScheduleCustom : workDayScheduleDefault,
  );
  const getMaxBookingDate =
    maxBookingStartLimitedBy === 'date' ? new Date(maxBookingStartDate) : addDays(new Date(), maxBookingStartOffset);
  const [maxBookingDate, setMaxBookingDate] = useState(getMaxBookingDate);

  useEffect(() => {
    setMaxBookingDate(getMaxBookingDate);
  }, [maxBookingStartLimitedBy, maxBookingStartDate, maxBookingStartOffset]);

  useEffect(() => {
    workDayScheduleCustomEnabled
      ? setWorkDaySchedule(workDayScheduleCustom)
      : setWorkDaySchedule(workDayScheduleDefault);
  }, [workDayScheduleDefault, workDayScheduleCustomEnabled, workDayScheduleCustom]);

  const getStartWorkDay = (day) => {
    const weekDay = getISODay(day) - 1;
    return setTimeWorkDay(day, weekDay, 'Start');
  };

  const getEndWorkDay = (day) => {
    const weekDay = getISODay(day) - 1;
    return setTimeWorkDay(day, weekDay, 'End');
  };
  const setTimeWorkDay = (day, weekDay, type) =>
    workDayScheduleCustomEnabled
      ? set(day, {
          hours: workDayScheduleCustom[weekDay][`hour${type}`],
          minutes: workDayScheduleCustom[weekDay][`minute${type}`],
          seconds: 0,
        })
      : set(day, {
          hours: workDayScheduleDefault[weekDay][`hour${type}`],
          minutes: workDayScheduleDefault[weekDay][`minute${type}`],
          seconds: 0,
        });

  const currentTime = set(new Date(), { seconds: 0 });
  const midnight = set(new Date(), {
    hours: WorkDaySettings.MIDNIGHT,
    minutes: 0,
    seconds: 0,
  });
  const nextDayMidnight = set(new Date(), {
    hours: WorkDaySettings.NEXT_DAY_MIDNIGHT,
    minutes: 0,
    seconds: 0,
  });
  const elevenPM = set(new Date(), {
    hours: WorkDaySettings.ELEVEN_PM,
    minutes: 0,
    seconds: 0,
  });

  const setDefaultDayTime = (bookDay, availableList, bookingList) => {
    let data = {};
    const startWorkDayTime = getStartWorkDay(bookDay);
    const endWorkDayTime = getEndWorkDay(bookDay);
    const bookingEndDay = bookingList.find((el) => {
      return new Date(currentTime).getTime() < new Date(el.startTime).getTime();
    });
    if (
      isToday(new Date(bookDay)) &&
      bookingEndDay &&
      new Date().getTime() < new Date(bookingEndDay.endTime).getTime()
    ) {
      data = { ...data, startTime: currentTime, endTime: new Date(bookingEndDay.startTime) };
    } else if (isToday(new Date(bookDay))) {
      if (currentTime < endWorkDayTime && currentTime > startWorkDayTime) {
        data = { ...data, startTime: currentTime, endTime: endWorkDayTime };
      } else if (currentTime < elevenPM && currentTime > endWorkDayTime) {
        data = {
          ...data,
          startTime: currentTime,
          endTime: new Date(new Date().setHours(currentTime.getHours() + 1, currentTime.getMinutes(), 0)),
        };
      } else if (currentTime >= elevenPM && currentTime < nextDayMidnight) {
        data = { ...data, startTime: currentTime, endTime: nextDayMidnight };
      } else if (currentTime >= midnight && currentTime < startWorkDayTime) {
        data = { ...data, startTime: startWorkDayTime, endTime: endWorkDayTime };
      }
    } else {
      if (availableList.length) {
        data = {
          startTime: availableList[0].startTime,
          endTime: availableList[0].endTime,
        };
      } else {
        data = {
          startTime: new Date(bookingList[bookingList.length - 1].endTime),
          endTime: addMinutes(new Date(bookingList[bookingList.length - 1].endTime), 60),
        };
      }
    }
    dispatch(setDefaultTime(data));
  };

  return {
    currentTime,
    getStartWorkDay,
    getEndWorkDay,
    midnight,
    nextDayMidnight,
    elevenPM,
    workDaySchedule,
    setDefaultDayTime,
    maxBookingDate,
  };
};

export default useDefaultBookingTime;
