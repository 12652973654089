import React, { useRef } from 'react';
import './MultiRangeSlider.scss';

const MultiRangeSlider = ({ min, max, widthRange, positionRange }) => {
  const range = useRef(null);
  return (
    <div className={'wrapper-slider'} style={{ left: positionRange }}>
      <input type="range" min={min} max={max} disabled className="thumb thumb--left" style={{ width: widthRange }} />
      <div className="slider" style={{ width: widthRange }}>
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
    </div>
  );
};

export default MultiRangeSlider;
