const env = {
  publicUrl: process.env.PUBLIC_URL || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  locale: {
    force: process.env.REACT_APP_LOCALE_FORCE,
  },
  sentryDns: process.env.SENTRY_DNS,
};

export default env;
