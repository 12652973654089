import React, { useContext, useEffect, useRef, useState } from 'react';
import './BookingPage.scss';
import { Calendar, Clock } from '../icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TimeRangeButtons from '../components/TimeRangeButtons';
import SliderRange from '../components/SliderRange';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableTime,
  getListAvailableIntervalsEndTime,
  getListAvailableIntervalsStartTime,
} from '../services/utils/date';
import format from 'date-fns/format';
import { bookPlace, getBookingsUsers, setActiveInterval, setAvailableTimeList } from '../redux/booking';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { addMinutes, isSameDay, set } from 'date-fns';
import { PATH_APP } from '../routes';
import { setBookDay, setTime } from '../redux/date';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { INTERVALS } from '../const/date';
import { setUserSelected } from '../redux/users';
import DatePicker from '../components/DatePicker';
import TimeSelection from '../components/TimeSelection';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';
import { sendActionLogs } from '../services/utils/logs';

const validationSchema = yup.object({
  bookDate: yup.string().required(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
});

const BookingPage = ({ setOpenNotification }) => {
  const { device, companySettings, settings } = useContext(DeviceAuthContext);
  const { setDefaultDayTime, getStartWorkDay, getEndWorkDay } = useDefaultBookingTime();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const redux = useSelector((state) => ({
    bookDay: state.date.bookDay,
    startTime: state.date.startTime,
    endTime: state.date.endTime,
    bookingList: state.booking.bookingList,
    availableList: state.booking.availableList,
    activeInterval: state.booking.activeInterval,
    users: state.users.list,
    minBookingInterval: state.settings.minBookingInterval,
    workDayInHours: state.settings.workDayInHours,
    isLoading: state.booking.isLoading,
    errors: state.booking.errors,
    userSelected: state.users.userSelected,
    placeInfo: state.place.info,
  }));
  const [togglePicker, setTogglePicker] = useState(false);
  const [disabledIntervals, setDisabledIntervals] = useState({
    interval120: false,
    interval240: false,
    interval480: false,
  });
  const [timeSelectRanges, setTimeSelectRanges] = useState({
    startTime: [],
    endTime: [],
  });
  const [openSelectTime, setOpenSelectTime] = useState({
    startTime: false,
    endTime: false,
  });
  const [error, setError] = useState(null);
  const initialValues = {
    bookDate: redux.bookDay,
    startTime: redux.startTime,
    endTime: redux.endTime,
    owner: '',
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      sendActionLogs({
        placeInfo: redux.placeInfo,
        errors: redux.errors,
        device,
        companySettings,
        settings,
        actionName: `Click on reserve button (submit)`,
        actionPath: window.location.pathname,
      });
      const dto = {
        places: device.place,
        initiator: redux.userSelected._id,
        startTime: new Date(values.startTime).toISOString(),
        endTime: new Date(values.endTime).toISOString(),
      };
      try {
        await dispatch(bookPlace(dto));
        await dispatch(setBookDay(new Date()));
        await dispatch(setUserSelected(null));
        setOpenNotification(true);
      } catch (err) {
        console.error(err);
      }
    },
  });

  useEffect(() => {
    const startDay = getStartWorkDay(redux.bookDay);
    const endDay = getEndWorkDay(redux.bookDay);
    dispatch(setAvailableTimeList(getAvailableTime(redux.bookingList, redux.bookDay, startDay, endDay)));
  }, [redux.bookingList]);

  const getBookings = async () => {
    try {
      if (device.floor && device.place) {
        await dispatch(
          getBookingsUsers({
            placeId: device.place,
            workDayInHours: redux.workDayInHours,
            params: {
              bookDate: new Date(formik.values.bookDate),
              startTime: set(new Date(formik.values.bookDate), {
                hours: 0,
                minutes: 0,
                seconds: 5,
                milliseconds: 0,
              }).toISOString(),
              endTime: set(new Date(formik.values.bookDate), {
                hours: 23,
                minutes: 59,
                seconds: 55,
                milliseconds: 0,
              }).toISOString(),
            },
          }),
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const checkIntervalAvailability = (startTime, interval) => {
    const newEndTime = addMinutes(new Date(startTime), interval);
    if (isSameDay(redux.bookDay, newEndTime)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setDefaultDayTime(redux.bookDay, redux.availableList, redux.bookingList);
  }, [redux.availableList, redux.bookingList]);

  useEffect(() => {
    if (!redux.userSelected) {
      navigate(PATH_APP.users);
    }
  }, [redux.userSelected]);

  useEffect(() => {
    const startTimeRange = getListAvailableIntervalsStartTime(
      new Date(redux.bookDay),
      [],
      redux.minBookingInterval || 15,
    );
    const endTimeRange = getListAvailableIntervalsEndTime(
      new Date(redux.bookDay),
      [],
      formik.values.startTime,
      redux.minBookingInterval || 15,
    );
    if (startTimeRange.length) {
      setTimeSelectRanges((prev) => ({
        ...prev,
        startTime: startTimeRange,
        endTime: endTimeRange,
      }));
      if (new Date(formik.values.startTime).getTime() > new Date(formik.values.endTime).getTime()) {
        formik.setFieldValue('endTime', endTimeRange[0]);
      }
    }
    dispatch(setActiveInterval(null));
    setDisabledIntervals({
      interval120: checkIntervalAvailability(formik.values.startTime, INTERVALS.interval120),
      interval240: checkIntervalAvailability(formik.values.startTime, INTERVALS.interval240),
      interval480: checkIntervalAvailability(formik.values.startTime, INTERVALS.interval480),
    });
  }, [redux.bookDay, formik.values.startTime, redux.minBookingInterval]);

  useEffect(() => {
    if (redux.startTime) {
      formik.setFieldValue('startTime', redux.startTime);
    }
  }, [redux.startTime]);

  useEffect(() => {
    if (redux.endTime) {
      formik.setFieldValue('endTime', redux.endTime);
    }
  }, [redux.endTime]);

  useEffect(() => {
    if (redux.activeInterval) {
      const newEndTime = addMinutes(new Date(formik.values.startTime), redux.activeInterval);
      if (isSameDay(redux.bookDay, newEndTime)) {
        dispatch(
          setTime({
            startTime: formik.values.startTime,
            endTime: newEndTime,
          }),
        );
      }
    }
  }, [redux.activeInterval]);

  useEffect(() => {
    getBookings();
    dispatch(setBookDay(new Date(formik.values.bookDate)));
  }, [formik.values.bookDate, redux.workDayInHours]);

  useEffect(() => {
    if (bookDateRef.current) {
      const closePicker = (e) => !bookDateRef.current.contains(e.target) && setTogglePicker(false);
      document.addEventListener('click', closePicker);
      return () => document.removeEventListener('click', closePicker);
    }
  }, [bookDateRef.current]);

  useEffect(() => {
    let closeSelectTime;
    if (startTimeRef.current) {
      closeSelectTime = (e) =>
        !startTimeRef.current.contains(e.target) &&
        setOpenSelectTime({
          startTime: false,
          endTime: false,
        });
    } else if (endTimeRef.current) {
      closeSelectTime = (e) =>
        !endTimeRef.current.contains(e.target) &&
        setOpenSelectTime({
          startTime: false,
          endTime: false,
        });
    }
    document.addEventListener('click', closeSelectTime);
    return () => document.removeEventListener('click', closeSelectTime);
  }, [startTimeRef.current, endTimeRef.current]);

  const handleTogglePicker = () => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Toggle date picker`,
      actionPath: window.location.pathname,
    });
    setTogglePicker(!togglePicker);
    setOpenSelectTime({
      startTime: false,
      endTime: false,
    });
  };

  const handleChangeBookDate = (e) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Click on book date`,
      actionPath: window.location.pathname,
    });
    setTogglePicker(false);
    formik.setFieldValue('bookDate', new Date(e));
  };

  const handelClickSelectTime = (type) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Click on select ${type} field for to open list`,
      actionPath: window.location.pathname,
    });
    setTogglePicker(false);
    setOpenSelectTime({
      startTime: type === 'startTime' ? !openSelectTime[type] : false,
      endTime: type === 'endTime' ? !openSelectTime[type] : false,
    });
  };

  const handelSelectStartTime = (e) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Select Start Time',
      actionPath: window.location.pathname,
    });
    const value = e.target.attributes.value.value;
    dispatch(
      setTime({
        startTime: value,
        endTime: formik.values.endTime,
      }),
    );
    dispatch(setActiveInterval(null));
    setOpenSelectTime({
      ...openSelectTime,
      startTime: false,
    });
  };

  const handelSelectEndTime = (e) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Select End Time',
      actionPath: window.location.pathname,
    });
    const value = e.target.attributes.value.value;
    dispatch(
      setTime({
        startTime: formik.values.startTime,
        endTime: value,
      }),
    );
    dispatch(setActiveInterval(null));
    setOpenSelectTime({
      ...openSelectTime,
      endTime: false,
    });
  };

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [error]);

  const hasError = (field) => Boolean(formik.touched[field] && formik.errors[field]);
  const helperTextFor = (field, defaultText) => (hasError(field) ? formik.errors[field] : defaultText);

  return (
    <div className="content_item-booking">
      <button
        className="button-back"
        onClick={() => {
          sendActionLogs({
            placeInfo: redux.placeInfo,
            errors: redux.errors,
            device,
            companySettings,
            settings,
            actionName: `Click on back button`,
            actionPath: window.location.pathname,
          });
          navigate(PATH_APP.users);
        }}
      >
        <div className="button-back_arrow" />
        <div>
          <Trans i18nKey="form.title" />
        </div>
      </button>
      <div className="form">
        {redux.isLoading ? (
          <Spinner />
        ) : (
          <form noValidate className="form_wrapper" onSubmit={formik.handleSubmit}>
            <div className="form_container">
              <div className="form_section">
                <ul className="inputs">
                  <li className={`${hasError('bookDate') ? 'inputs_error' : ''}inputs_item`}>
                    <label className="label" htmlFor="date">
                      <Trans i18nKey="form.date" />
                    </label>
                    <div className="input_date-picker" ref={bookDateRef}>
                      <div className="input_date" onClick={handleTogglePicker} />
                      {togglePicker && (
                        <DatePicker initialDate={formik.values.bookDate} onChange={handleChangeBookDate} />
                      )}
                      <div className="input_icon">
                        <Calendar />
                      </div>
                      {format(new Date(formik.values.bookDate), 'E MMM d')}
                    </div>
                    {helperTextFor('bookDate') && <div className="error">{helperTextFor('bookDate')}</div>}
                  </li>
                  <li className={`${hasError('startTime') ? 'inputs_error' : ''} inputs_item`}>
                    <label className="label" htmlFor="start-time">
                      <Trans i18nKey="form.start" />
                    </label>
                    <div className="input_wrapper">
                      <div
                        id="start-time"
                        className="input_elem input_select_time"
                        onClick={() => handelClickSelectTime('startTime')}
                      >
                        {formik.values.startTime ? format(new Date(formik.values.startTime), 'h:mm a') : ''}
                      </div>
                      {openSelectTime.startTime && (
                        <TimeSelection
                          scrollAfter={formik.values.startTime}
                          options={timeSelectRanges.startTime}
                          onClick={handelSelectStartTime}
                          open={openSelectTime.startTime}
                        />
                      )}
                      <div className="input_icon">
                        <Clock />
                      </div>
                    </div>
                    {helperTextFor('startTime') && <div className="error">{helperTextFor('startTime')}</div>}
                  </li>
                  <li className={`${hasError('endTime') ? 'inputs_error' : ''} inputs_item`}>
                    <label className="label" htmlFor="end-time">
                      <Trans i18nKey="form.end" />
                    </label>
                    <div className="input_wrapper">
                      <div
                        id="end-time"
                        className="input_elem input_select_time"
                        onClick={() => handelClickSelectTime('endTime')}
                      >
                        {formik.values.endTime ? format(new Date(formik.values.endTime), 'h:mm a') : ''}
                      </div>
                      {openSelectTime.endTime && (
                        <TimeSelection
                          scrollAfter={formik.values.endTime}
                          options={timeSelectRanges.endTime}
                          onClick={handelSelectEndTime}
                          open={openSelectTime.endTime}
                        />
                      )}
                      <div className="input_icon">
                        <Clock />
                      </div>
                    </div>
                    {helperTextFor('endTime') && <div className="error">{helperTextFor('endTime')}</div>}
                  </li>
                </ul>
              </div>
              <div className="form_section form_range">
                <div className="form_range-item">
                  <TimeRangeButtons
                    className="form_buttons--range"
                    disabledIntervals={disabledIntervals}
                    device={device}
                    companySettings={companySettings}
                    settings={settings}
                  />
                </div>
                <div className="form_range-item">
                  <SliderRange />
                </div>
              </div>
            </div>
            <div className="form_buttons">
              {error && <div className="error_text">{error}</div>}
              <button type="submit" className="button">
                {formik.isSubmitting ? <Spinner /> : <Trans i18nKey="reserve_btn" />}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default BookingPage;
