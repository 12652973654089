import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

const initialState = {
  bookDay: new Date(),
  startTime: null,
  endTime: null,
};

const slice = createSlice({
  initialState,
  name: 'date',
  reducers: {
    startLoading() {},
    hasError(state, action) {
      Sentry.captureException(action.payload);
      console.error(action.payload);
    },
    setBookDaySuccess(state, action) {
      state.bookDay = action.payload;
    },
    setTimeSuccess(state, action) {
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
    },
    setDefaultTimeSuccess(state, action) {
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
    },
  },
});

// Reducer
export const dateReducer = slice.reducer;

// ----------------------------------------------------------------------

export const setBookDay = (date) => async (dispatch) => {
  dispatch(slice.actions.setBookDaySuccess(date));
};

export const setTime = (time) => async (dispatch) => {
  dispatch(slice.actions.setTimeSuccess(time));
};

export const setDefaultTime = (data) => (dispatch) => {
  dispatch(slice.actions.setDefaultTimeSuccess(data));
};
// ----------------------------------------------------------------------
