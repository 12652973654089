import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import './TimeRangeButtons.scss';
import { setActiveInterval } from '../redux/booking';
import { INTERVALS } from '../const/date';
import { sendActionLogs } from '../services/utils/logs';

const TimeRangeButtons = ({ title, className, disabledIntervals, device, companySettings, settings }) => {
  const dispatch = useDispatch();
  const redux = useSelector((state) => ({
    activeInterval: state.booking.activeInterval,
    bookDay: state.date.bookDay,
    startTime: state.date.startTime,
    endTime: state.date.endTime,
    placeInfo: state.place.info,
    errors: state.booking.errors,
  }));
  const [activeInt, setActiveInt] = useState(redux.activeInterval);

  const handleClickBookBtn = (interval) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Click on ${interval} interval button`,
      actionPath: window.location.pathname,
    });
    dispatch(setActiveInterval(interval));
  };

  useEffect(() => {
    setActiveInt(redux.activeInterval);
  }, [redux.activeInterval]);

  return (
    <div className={`buttons-interval ${className}`}>
      {title && (
        <div className="buttons-interval_title">
          <Trans i18nKey={title} />
        </div>
      )}
      <ul className="buttons-interval_list">
        <li
          className={`buttons-interval_item ${
            activeInt === INTERVALS.interval120 ? 'gradient-border' : 'buttons-interval_border'
          }`}
        >
          <button
            type="button"
            className={`buttons-interval_btn ${disabledIntervals.interval120 ? 'buttons-interval_disable' : ''}`}
            onClick={() => handleClickBookBtn(INTERVALS.interval120)}
          >
            <Trans i18nKey="button_interval_two" />
          </button>
        </li>
        <li
          className={`buttons-interval_item ${
            activeInt === INTERVALS.interval240 ? 'gradient-border' : 'buttons-interval_border'
          }`}
        >
          <button
            type="button"
            className={`buttons-interval_btn ${disabledIntervals.interval240 ? 'buttons-interval_disable' : ''}`}
            onClick={() => handleClickBookBtn(INTERVALS.interval240)}
          >
            <Trans i18nKey="button_interval_four" />
          </button>
        </li>
        <li
          className={`buttons-interval_item ${
            activeInt === INTERVALS.interval480 ? 'gradient-border' : 'buttons-interval_border'
          }`}
        >
          <button
            type="button"
            className={`buttons-interval_btn ${disabledIntervals.interval480 ? 'buttons-interval_disable' : ''}`}
            onClick={() => handleClickBookBtn(INTERVALS.interval480)}
          >
            <Trans i18nKey="button_interval_eight" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TimeRangeButtons;
